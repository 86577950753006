import {
  ref,
  computed,
  // readonly,
} from '@vue/composition-api'
// import instruments from '@/data/instruments.json'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'

const categories = ref({
  active: { id: 0, name: 'new' },
  list: [],
})

export default function useCategories() {
  const { http } = useApollo()
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  // const loading = ref(false)

  async function getCategories() {
    if (categories.value.list.length < 1) {
      await http.value.query({
        query: gql`
          query getCategories {
           ${frontSchema}_categories {
              id
              name
            }
          }
        `,
      })
        .then(result => {
          categories.value.list = result.data[`${frontSchema}_categories`]
        })
        .catch(err => console.error(err))
    }
  }

  function setCategoryActive({ newCategory }) {
    categories.value.active = newCategory
  }

  return {
    categoriesList: computed(() => categories.value.list),
    categoryActive: computed(() => categories.value.active),
    getCategories,
    setCategoryActive,
  }
}
