<template>
  <div>
    <slot
      name="name"
      class="met-name"
    />
    <slot
      name="value"
      class="met-value"
    />
  </div>
</template>

<script>

export default {
  // components: {
  //   Card,
  // },
}
</script>

<style lang="scss">

.metric-block {
  background: white;
  &.basic {
    .met-icon {
      font-size: 40px;
    }
    .met-name {
      font-size: 20px;
      font-weight: 600;
    }
    .met-value {
      font-weight: 500;
    }
  }
}

</style>
