<script>
import { useToast } from 'vue-toastification/composition'

/*
Toast types:

info
success
error
warning

*/
export default {
  name: 'Toast',
  setup() {
    // toast setup, can add custom options
    const toast = useToast({ timeout: 2500 })

    // functions to export
    function showToast(toastType, toastMessage) {
      toast[toastType](toastMessage)
    }
    function clearToasts() {
      toast.clear()
    }
    return {
      showToast,
      clearToasts,
    }
  },
}
</script>

<style>

</style>
