<template>
  <div
    v-if="index === tabPage.activeIndex"
    class="tab-content"
  >
    <slot />
  </div>
</template>

<script>
import useTabs from '@/composables/useTabs'

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { tabPage } = useTabs()
    return {
      tabPage,
    }
  },
}
</script>

<style lang="scss">

</style>
