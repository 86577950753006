<template>
  <div :class="'tab-wrap ' + tabDirection">
    <div>
      <slot name="above" />
      <div class="tab-bar">
        <slot
          name="instruments"
        />
        <div class="tab-bar-scroll">
          <slot
            name="before"
          />
          <div
            v-for="tab in tabPage.tabs"
            :key="tab.id"
            class="tabs"
          >
            <button
              :id="'tabButton' + tab.name.replace(/\s+/g, '')"
              class="tab-button"
              @click="setTabActive({ tab }), setterFunction({ tab })"
            >
              {{ tab.name }}
            </button>
          </div>
          <slot
            name="after"
          />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import useTabs from '@/composables/useTabs'
import { onMounted } from '@vue/composition-api'
import useCategories from '@/composables/useCategories'
import useStrategies from '@/composables/strategies/useStrategies'
import useInstruments from '@/composables/useInstruments'

export default {
  props: {
    tabDirection: {
      type: String,
      default: 'tab-row',
    },
  },
  setup() {
    const { tabPage, pageName, setTabActive } = useTabs()
    const { setCategoryActive } = useCategories()
    const { instrumentActive } = useInstruments()
    const { setStrategyActive } = useStrategies({ instrumentActive })

    onMounted(() => {
      document.getElementById(tabPage.value.activeId).click()
    })

    function setterFunction({ tab }) {
      if (pageName.value === 'home') {
        setCategoryActive({
          newCategory: { name: tab.name.toLowerCase(), id: tab.index },
        })
      } else if (pageName.value === 'strategies') {
        setStrategyActive(tab.name.replace(' Strategies', ''))
      } else if (pageName.value === 'analysis') {
        setStrategyActive(tab.name.replace(' Trades', ''))
      } else if (pageName.value === 'performance') {
        setStrategyActive(tab.name)
      }
    }

    return {
      setterFunction,
      setTabActive,
      tabPage,
    }
  },
}
</script>

<style lang="scss">

</style>
