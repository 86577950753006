<template>
  <div class="no-pad-wrap">
    <div class="journal-wrap">
      <tabs class="bt-none">
        <template
          v-slot:above
        >
          <MetricsGrid
            :requested-metrics="'journal100'"
            :grid-type="'small'"
            :metrics="journalMetrics"
          />
        </template>
        <tab :index="0">
          <GridSetup
            :processed-data="journalTrades"
            :pagination="true"
          />
        </tab>
        <tab :index="1">
          <EChartHeatmap />
          <EChartSingleLineScatter />
        </tab>
        <template v-slot:after>
          <div class="instrument-filter">
            <input
              id="signalFilter"
              type="checkbox"
              name="filter"
              :checked="instrumentFilter"
              @click="toggleInstrumentFilter($event.srcElement.checked)"
            >
            <label
              class="filter-label"
              for="filter"
            >{{ instrumentActive.name.replace(/_/g, "/") }} Only</label>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>

// import Multiselect from 'vue-multiselect'
// import EChartHeatmap from '../components/echart/EChartHeatmap.vue'
// import MetricsGrid from '../components/MetricsGrid.vue'
// import EChartSingleLineScatter from '../components/echart/EChartSingleLineScatter.vue'

// import InstrumentDropdown from '@/views/components/InstrumentDropdown.vue'
import Toast from '@/mixins/Toast.vue'
import useInstruments from '@/composables/useInstruments'
import useJournalTrades from '@/composables/journal/useJournalTrades'
// import usePermissions from '@/composables/usePermissions'
import MetricsGrid from '../components/metrics/MetricsGrid.vue'
import tab from '../components/appTab.vue'
import tabs from '../components/appTabs.vue'
import GridSetup from '../components/agGrid/GridSetup.vue'

export default {
  components: {
    MetricsGrid,
    tabs,
    tab,
    GridSetup,
    // InstrumentDropdown,
  },
  mixins: [Toast],
  setup() {
    const {
      setInstrumentActive,
      instrumentActive,
      instrumentList,
      toggleInstrumentFilter,
      instrumentFilter,
    } = useInstruments()
    const {
      journalTrades,
      journalMetrics,
      journalOpenPositions,
      // getFullJournal,
      toggleJournalFilter,
    } = useJournalTrades()

    // const { access } = usePermissions()

    // getJournalMetrics()

    // getFullJournal()
    // const hasAccess = access.content('profileJournal')
    // if (hasAccess) {
    // }

    return {
      setInstrumentActive,
      instrumentActive,
      instrumentList,
      toggleInstrumentFilter,
      instrumentFilter,
      //
      journalTrades,
      journalMetrics,
      journalOpenPositions,
      toggleJournalFilter,
      //
      // hasAccess,
    }
  },
  // mounted() {
  //   if (!this.hasAccess) {
  //     this.showToast('warning', 'Your plan does not have access to the profile journal')
  //     console.log('setting grid height')
  //   }
  // },
}
</script>

<style lang="scss">

.journal-wrap {
  height: 100%;
}

</style>
