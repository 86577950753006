<template>
  <div>
    <div
      v-if="metrics"
      class="metrics-grid"
    >
      <MetricBlockBasic
        v-for="metric in metrics"
        :key="metric.name"
        class="single-metric"
      >
        <template v-slot:name>
          <h4 class="metric-name">
            {{ metric.name }}
          </h4>
        </template>
        <template
          v-slot:value
        >
          <span> {{ metric.value }} </span>
        </template>
      </MetricBlockBasic>
    </div>
    <div
      v-else
      class="metrics-grid"
    >
      <MetricBlockBasic
        v-for="metric in noTrades"
        :key="metric.name"
        class="single-metric"
      >
        <template v-slot:name>
          <h4 class="metric-name">
            {{ metric.name }}
          </h4>
        </template>
        <template
          v-slot:value
        >
          <span> {{ metric.value }} </span>
        </template>
      </MetricBlockBasic>
    </div>
  </div>
</template>

<script>
import MetricBlockBasic from '@/views/components/metrics/AppMetricBlockBasic.vue'
// import useInstruments from '@/composables/useInstruments'

export default {
  name: 'MetricsGrid',
  components: {
    MetricBlockBasic,
  },
  props: {
    gridType: {
      type: String,
      default: '',
      /*
        small
        compact
        regular
      */
    },
    metrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noTrades: [{ name: 'No Trades', value: 'N/A' }, { name: 'No Trades', value: 'N/A' }],
    }
  },
  computed: {
    typeOfGrid() {
      return this.gridType
    },
  },
  mounted() {
    this.setStyle()
  },
  methods: {
    setStyle() {
      const grid = document.getElementsByClassName('metrics-grid')
      for (let i = 0; i < grid.length; i += 1) {
        grid[i].className += ` ${this.typeOfGrid}`
      }
    },
  },
}
</script>

<style lang="scss">
.metrics-grid {
  display: grid;
  overflow: hidden;
  width: 100%;
  .single-metric {
    background-color: white;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &.regular {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    padding: 2rem;
    gap: 2rem;
    .single-metric {
      padding: 1rem;
      border-radius: 8px;
      height: 100%;
      border-radius: 8px;
      box-shadow: 5px 6px 20px 5px hsla(0, 0%, 0%, 0.10);
    }
    .metric-name {
      margin-bottom: 0.5rem;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &.compact {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    .single-metric {
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      min-height: 50px;
      padding: 0.8rem;
    }
    .metric-name {
      margin: 0px;
      padding-right: 1rem;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.small {
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-auto-flow: column;
    overflow: auto;
    width: 100%;
    column-gap: 5px;
    align-items: center;
    min-height: 50px;
    background-color: #f2f2f2;
    .single-metric {
      display: flex;
      height: 40px;
      white-space: nowrap;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 14px;
    }
    .metric-name {
      margin: 0px;
      padding-right: 1rem;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
